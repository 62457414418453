<div class="wrapper">
    <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel($event)"></app-error-model>
    <app-success-model
        message="{{ sMessage }}"
        [display]="displaySuccess"
        (event)="closeModel($event)"></app-success-model>
    <app-navigation *ngIf="loginService.isAdmin()"></app-navigation>
    <app-enterprise-navigation *ngIf="loginService.isEnterprise()"></app-enterprise-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <app-header location="User Account Profile"></app-header>
        <app-enterprise-links [id]="id" [isAdmin]="loginService.isAdmin()"></app-enterprise-links>
        <section class="edit-section-profile">
            <div>
                <div class="profile-placeholder">
                    <a (click)="fileInput.click()"
                        ><img src="{{ user.businessLogo }}" *ngIf="user.businessLogo"
                    /></a>
                    <a (click)="fileInput.click()">
                        <img src="assets/images/placeholder-large.png" *ngIf="!user.businessLogo" />
                    </a>
                    <input
                        type="file"
                        (change)="changeProfile($event.target.files)"
                        hidden
                        #fileInput
                        accept="image/jpeg,image/png" />
                </div>
                <div class="profile-content">
                    <div class="row">
                        <div class="col-md-12 section-heading">
                            {{ user.businessName || '-' }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <label>MOBILE NUMBER:</label>
                            <span class="user-details-text">{{
                                user.primaryContact.mobileNumber || '-'
                            }}</span>
                        </div>
                        <div class="col-md-7">
                            <label>STATUS:</label>
                            <span class="user-details-text">{{ user.status || '' }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <label>DATE CREATED:</label>
                            <span class="user-details-text">{{
                                user.createdAt | date: dateFormat || '-'
                            }}</span>
                        </div>
                        <div class="col-md-7">
                            <label>LAST LOGIN:</label>
                            <span class="user-details-text">
                                @if (user.lastLogin) {
                                    {{ user.lastLogin | date: dateTimeFormat }}
                                } @else {
                                    Never
                                }
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label>ASSOCIATED ACCOUNTS:</label>
                            <span class="user-details-text">Enterprise</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-wallet">
            <div class="inner-tab">
                <button class="inner-tab-link active">ENTERPRISE</button>
            </div>
            <div class="wallet-tab-content active">
                <div class="row">
                    <div class="col-md-6 section-heading">Wallet Balance</div>
                </div>
                <div class="label-value">
                    {{ user.publicKey || '' }}
                    <div>
                        <a (click)="copyText(user.publicKey)">
                            <img src="assets/images/copy-icon.png" />copy
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label>FULL:</label>
                    </div>
                    <div class="col-md-6">
                        <label>AVAILABLE:</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <span>{{ user.fullBalance || '0.00' | number: amountFormat }}</span>
                    </div>
                    <div class="col-md-6">
                        <span>{{ user.availableBalance || '0.00' | number: amountFormat }}</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="add-section">
            <div class="row">
                <div class="col-md-12 section-heading">Enterprise Details</div>
            </div>
            <div class="details-view">
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label">BUSINESS NAME</label>
                        <div class="user-details-text">
                            {{ user.businessName || '-' }}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <app-user-address
            [address]="user.address"
            type="office"
            (update)="updateAddress($event)"></app-user-address>
        <app-user-bank-data [bank]="user.bank" (update)="updateBank($event)"></app-user-bank-data>
        <section class="add-section">
            <div class="row">
                <div class="col-md-8 section-heading">Primary Contact Details</div>
                <div class="col-md-4 section-heading" *ngIf="loginService.isAdmin()">
                    <app-edit-pencil (edit)="changeView($event, 'enterprise')"></app-edit-pencil>
                </div>
            </div>
            <div *ngIf="editEnterprise">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">NAME*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    formControlName="firstName"
                                    name="firstName"
                                    placeholder="User First Name" />
                            </div>
                            <small
                                *ngIf="form.get('firstName').invalid && invalidForm"
                                class="error-text">
                                This field is required
                            </small>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">SURNAME*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    formControlName="lastName"
                                    name="lastName"
                                    placeholder="User Last Name" />
                            </div>
                            <small
                                *ngIf="form.get('lastName').invalid && invalidForm"
                                class="error-text">
                                This field is required
                            </small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">EMAIL ADDRESS*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="email"
                                    placeholder="e.g. email@gmail.com"
                                    name="email"
                                    formControlName="email"
                                    email />
                            </div>
                            <small
                                *ngIf="(form.get('email').invalid && invalidForm) || emailExist"
                                class="error-text"
                                >{{ emailErrorMsg }}
                            </small>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">MOBILE NUMBER*</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="e.g. 8978978999"
                                    name="mobileNumber"
                                    formControlName="mobileNumber" />
                            </div>
                            <small
                                *ngIf="
                                    (form.get('mobileNumber').invalid && invalidForm) || mobileExist
                                "
                                class="error-text"
                                >{{ mobileErrorMsg }}
                            </small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6">
                            <button class="custombtn custombtn-align-right" (click)="submit()">
                                SAVE
                            </button>
                            <a class="user-files-deny" (click)="changeView($event, 'enterprise')"
                                ><u>CANCEL</u></a
                            >
                        </div>
                    </div>
                </form>
            </div>
            <div class="row" *ngIf="!editEnterprise">
                <div class="col-md-3">
                    <label class="form-label">NAME</label>
                    <div class="user-details-text">
                        {{ user.primaryContact.firstName || '-' }}
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="form-label">SURNAME</label>
                    <div class="user-details-text">
                        {{ user.primaryContact.lastName || '-' }}
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="form-label">MOBILE NUMBER</label>
                    <div class="user-details-text">
                        {{ user.primaryContact.mobileNumber || '-' }}
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="form-label">EMAIL ADDRESS</label>
                    <div class="user-details-text">
                        {{ user.primaryContact.email || '-' }}
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
