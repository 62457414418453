import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericResponse } from '../model/generic.response';
import { AppSettings } from '../settings/app-settings';
import { ICashbackReport } from './../model/cashback.report';

@Injectable({ providedIn: 'root' })
export class CashbackReportService {
    constructor(
        private http: HttpClient
    ) { }

    getCashbackReports(queryParams: any) {
        const params = new HttpParams({ fromObject: queryParams });
        return this.http.get<GenericResponse<ICashbackReport[]>>(
            AppSettings.API_ENDPOINT + AppSettings.GET_CASHBACK_REPORTS,
            { params },
        );
    }
}
