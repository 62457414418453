export class URI {
    public static HOME = '/';
    public static LOGIN = 'login';
    public static SLASHED_LOGIN = '/login';
    public static LOGIN_TFA = 'login/tfa';
    public static SLASHED_LOGIN_TFA = '/login/tfa';
    public static PRIVACY_POLICY = 'privacy-policy';
    public static SLASHED_PRIVACY_POLICY = '/privacy-policy';
    public static TAC_PERSONAL = 't&c/personal';
    public static TAC_MERCHANT = 't&c/business';
    public static TAC_AGENT = 't&c/agent';
    public static FORGOT_PASSWORD = 'forgot-password/:id';
    public static CREATE_PASSWORD = 'create-password/:id';
    public static SLASHED_FORGOT_PASSWORD = '/forgot-password/:id';

    // Manage Users

    public static SEARCH = 'manage-users/search';
    public static SLASHED_SEARCH = '/manage-users/search';
    public static ADD_STAFF = 'manage-users/add/staff-user';
    public static SLASHED_ADD_STAFF = '/manage-users/add/staff-user';
    public static ADD_AGENT = 'manage-users/add/agent';
    public static SLASHED_ADD_AGENT = '/manage-users/add/agent';
    public static ADD_MERCHANT = 'manage-users/add/business';
    public static SLASHED_ADD_MERCHANT = '/manage-users/add/business';
    public static ADD_GENERAL_USER = 'manage-users/add/general-user';
    public static SLASHED_ADD_GENERAL_USER = '/manage-users/add/general-user';
    public static ADD_ENTERPRISE = 'manage-users/add/enterprise';
    public static SLASHED_ADD_ENTERPRISE = '/manage-users/add/enterprise';
    public static VIEW_PROFILE_PERSONAL = 'manage-users/view/profile/:id/personal';
    public static SLASHED_VIEW_PROFILE_PERSONAL = '/manage-users/view/profile/:id/personal';
    public static VIEW_PROFILE_SUMMARY = 'manage-users/view/profile/:id/summary';
    public static SLASHED_VIEW_PROFILE_SUMMARY = '/manage-users/view/profile/:id/summary';
    public static VIEW_PROFILE_MERCHANT = 'manage-users/view/profile/:id/business';
    public static SLASHED_VIEW_PROFILE_MERCHANT = '/manage-users/view/profile/:id/business';
    public static VIEW_PROFILE_AGENT = 'manage-users/view/profile/:id/agent';
    public static SLASHED_VIEW_PROFILE_AGENT = '/manage-users/view/profile/:id/agent';
    public static VIEW_PROFILE_FILES = 'manage-users/view/profile/:id/files';
    public static SLASHED_VIEW_PROFILE_FILES = '/manage-users/view/profile/:id/files';
    public static VIEW_PROFILE_SETTINGS = 'manage-users/view/profile/:id/settings';
    public static SLASHED_VIEW_PROFILE_SETTINGS = '/manage-users/view/profile/:id/settings';
    public static VIEW_USER_TRANSACTIONS = 'manage-users/view/profile/:id/transactions';
    public static SLASHED_VIEW_USER_TRANSACTIONS = '/manage-users/view/profile/:id/transactions';
    public static VIEW_ALL_USER_TRANSACTIONS = 'admin/view/transactions';
    public static SLASHED_VIEW_ALL_USER_TRANSACTIONS = '/admin/view/transactions';
    public static CHANGE_MOBILE = 'manage-users/search/results/change-mobile';
    public static SLASHED_CHANGE_MOBILE = '/manage-users/search/results/change-mobile';
    public static VERIFY_OTP = 'manage-users/search/results/change-mobile/verify-otp';
    public static SLASHED_VERIFY_OTP = '/manage-users/search/results/change-mobile/verify-otp';
    public static VERIFY_USERS = 'manage-users/verify-users';
    public static SLASHED_VERIFY_USERS = '/manage-users/verify-users';

    // Enterprise
    public static ENTERPRISE_DASHBOARD = 'enterprise/dashboard';
    public static SLASHED_ENTERPRISE_DASHBOARD = '/enterprise/dashboard';
    public static ENTERPRISE_PROFILE_SUMMARY = 'manage-users/enterprise/summary';
    public static SLASHED_ENTERPRISE_PROFILE_SUMMARY = '/manage-users/enterprise/summary';
    public static ENTERPRISE_PROFILE = 'enterprise/profile';
    public static SLASHED_ENTERPRISE_PROFILE = '/enterprise/profile';
    public static ENTERPRISE_EMPLOYEE = 'manage-users/enterprise/employee';
    public static SLASHED_ENTERPRISE_EMPLOYEE = '/manage-users/enterprise/employee';
    public static ENTERPRISE_SETTINGS = 'manage-users/enterprise/settings';
    public static SLASHED_ENTERPRISE_SETTINGS = '/manage-users/enterprise/settings';
    public static ENTERPRISE_CASHBACK_SETTINGS = 'enterprise/cashback/settings';
    public static SLASHED_ENTERPRISE_CASHBACK_SETTINGS = '/enterprise/cashback/settings';
    public static ENTERPRISE_TRANSACTION = 'enterprise/transactions';
    public static SLASHED_ENTERPRISE_TRANSACTION = '/enterprise/transactions';
    public static ENTERPRISE_TRANSACTION_ADMIN = 'manage-users/enterprise/transactions';
    public static SLASHED_ENTERPRISE_TRANSACTION_ADMIN = '/manage-users/enterprise/transactions';
    public static ENTERPRISE_TRANSFER_TO_BANK = 'enterprise/transfer-to-bank';
    public static SLASHED_ENTERPRISE_TRANSFER_TO_BANK = '/enterprise/transfer-to-bank';
    public static ENTERPRISE_PRODUCT_ORDERS = 'enterprise/product-orders';
    public static SLASHED_ENTERPRISE_PRODUCT_ORDERS = '/enterprise/product-orders';

    // GRV
    public static GRV = 'grv';
    public static SLASHED_GRV = '/grv';
    public static ADD_GRV = 'grv/add';
    public static SLASHED_ADD_GRV = '/grv/add';

    // Product Orders
    public static PRODUCT_ORDERS = 'admin/product-orders';

    private static uriList: { [key: string]: string } = {
        // Menu Items
        dashboard: 'dashboard',
        manageUsers: 'manage-users',
        adminViewTransactions: 'admin/view/transactions',
        adminProductOrders: 'admin/product-orders',
        adminTransferToBank: 'admin/transfer-to-bank',
        adminSettings: 'admin/settings',
        adminEFTCashIn: 'admin/eft-cash-in',
        adminSmartSafes: 'admin/smart-safes',
        adminAgentApplication: 'admin/agent-applications',
        adminGRV: 'admin/grv',
        adminBanners: 'admin/banners',

        // Admin Transfer to Bank
        adminTransferToBankPending: 'admin/transfer-to-bank/pending',
        adminTransferToBankProcessed: 'admin/transfer-to-bank/processed',
        adminTransferToBankManualExport: 'admin/transfer-to-bank/manual-export',
        adminTransferToBankExportLog: 'admin/transfer-to-bank/export-log',

        // Admin Transfer from Bank / EFT Cash In
        adminEFTCashInPending: 'admin/eft-cash-in/pending',
        adminEFTCashInReceived: 'admin/eft-cash-in/received',
        adminEFTCashInCancelled: 'admin/eft-cash-in/cancelled',

        // Admin Settings
        adminSettingsFees: 'admin/settings/fees',
        adminSettingsTCs: 'admin/settings/terms-and-conditions',
        adminSettingsLevelsAndLimits: 'admin/settings/levels-and-limit',

        // Smart Safe
        adminSmartSafeSummary: 'admin/smart-safes',
        adminSmartSafeTransactionBySafe: ':safeId/transactions',
        adminSmartSafeTransactionBySafeByUser: 'user-by-safe/users/:userRoleId',
        adminSmartSafeTransactionByUser: ':safeId/users/:userRoleId/transactions',

        cashInTransitCompany: 'admin/smart-safes/cash-in-transit',
        adminSmartSafeConfiguration: 'admin/smart-safes/configuration',
        adminSmartSafeEvents: 'admin/smart-safes/events',

        // Agent Applications
        adminAgentApplications: 'admin/agent-applications',
        adminAgentApplicationsActive: 'admin/agent-applications/active',
        adminAgentApplicationsActiveFiles: 'admin/agent-applications/active/files',
        adminAgentApplicationsDenied: 'admin/agent-applications/denied',

        // Banners
        adminBannersNew: 'admin/banners/new',
        adminBannersUpdate: 'admin/banners/:id',

        // Enterprise Links
        enterpriseDashboard: 'enterprise/dashboard',

        // Cashback report
        adminCashbackReport: 'admin/cashback-report',
    };

    public static getChildPathFromParent(parentRouteKey: string, action: string): string {
        return this.getPath(parentRouteKey).addSlash() + '/' + action;
    }

    public static getRoutePath(routeKey: string): string {
        return this.getPath(routeKey).uri;
    }

    public static getRouteNavPath(routeKey: string): string {
        return this.getPath(routeKey).addSlash();
    }

    private static getPath(key: string) {
        const uri = this.uriList[key];

        const path = {
            addSlash() {
                return '/' + uri;
            },
            uri,
        };
        return path;
    }
}
