import { FormControl, FormRecord } from '@angular/forms';
import { Pagination } from 'src/app/model/Pagination';


export class ListView {
    pagination = new Pagination();
    showAdvancedSearch = false;
    searchItemList = [];
    newControlsToAdd: { [key: string]: any } = {};
    searchFormControls = ['text', 'start', 'length'];
    searchForm: FormRecord = new FormRecord<FormControl<string | [] | null>>({});
    showSearchResultRow = false;
    isLoading = false;
    progressWidth = 25;
    searchItemsObj?: { [key: string]: {}[] } = {};
    searchItems?: { [key: string]: {}[] } = {};

    constructor() {
        this.searchFormControls.forEach((key) =>
            this.searchForm.addControl(key, new FormControl(''))
        );
    }

    getData() { }

    /*
        Make sure you have populated the newControlsToAdd object before calling this method
        Populate like this: this.newControlsToAdd.formControlName = formControlType;
        For example: formControlName could be varantIds and formControlType could be an Array [];
        this.newControlsToAdd.wholesalerId = '';
        this.newControlsToAdd.supplierId = '';
        this.newControlsToAdd.variantIds = [];
    */
    addNewControls() {
        Object.keys(this.newControlsToAdd).forEach((key: string) => {
            let value: any = this.newControlsToAdd[key];
            this.searchForm.addControl(key, new FormControl(value))
        });
    };

    clearText() {
        this.searchForm.get('text').setValue('');
    }

    receiveFilter(data: { name: string; value: number }) { }

    toggleAdvancedSearch() {
        this.showAdvancedSearch = !this.showAdvancedSearch;
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    /**
     * Iterates over the searchItemsObj and processes each key and value.
     * If the value is an array, it calls the addArrayItemsToSearchItems method to handle the array values.
     * If the value is not an array and the form value for that key is non-empty, it assigns the value directly to searchItems.
     */
    addSearchItems() {
        // Iterate over each key-value pair in the searchItemsObj object
        for (const [key, value] of Object.entries(this.searchItemsObj)) {
            // Check if the value is an array
            if (Array.isArray(value)) {
                // Call helper method to process array-type values
                this.addArrayItemsToSearchItems(key, value);
            } else if (this.searchForm.get(key).value?.length > 0) {
                // If the form field has a non-empty value, assign the value directly to searchItems
                this.searchItems[key] = value;
            }
        }
    }

    /**
     * Handles array-type values from searchItemsObj and adds unique 'name' properties to searchItems.
     * Initializes the key in searchItems if it does not exist yet.
     * @param key - The key in the searchItemsObj that corresponds to an array of items.
     * @param value - The array of objects that contains 'name' properties to be added.
     */
    private addArrayItemsToSearchItems(key: string, value: any[]) {
        // Initialize the key in searchItems if it doesn't exist yet
        if (!this.searchItems[key]) {
            this.searchItems[key] = [];
        }

        // Iterate through the array and add unique 'name' values to the searchItems array for the corresponding key
        for (const o of value) {
            // Check if the 'name' property is already in the array; if not, add it
            if (!this.searchItems[key].includes(o['name'])) {
                this.searchItems[key].push(o['name']);
            }
        }
    }

    // Fired when all items are to be removed from an ng-select dropdown
    onClear(key) {
        this.removeAllItemsByKey(key);
        this.getData();
    }

    onRemove(event, key) {
        this.removeItemByKeyValue(key, event);
        this.getData();
    }


    removeItemByKeyValue(key: string, event: any) {
        // debugger
        this.searchItems[key] = this.searchItems[key].filter((item) => item !== event['name']);
    }

    removeAllItemsByKey(key: string) {
        delete this.searchItems[key];
        delete this.searchItemsObj[key]
    }

    onChange(event, key) {
        // Initialize the key if it doesn't exist in searchItemsObj
        if (!this.searchItemsObj[key]) {
            this.searchItemsObj[key] = [];
        }

        // Check if the event is an array, and set or push to searchItemsObj[key] accordingly
        if (Array.isArray(event)) {
            this.searchItemsObj[key] = event;
        } else if (!this.searchItemsObj[key].includes(event)) {
            this.searchItemsObj[key].push(event);
        }
    }

}
