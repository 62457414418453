<div class="wrapper">
    <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel($event)"></app-error-model>
    <app-success-model
        message="{{ sMessage }}"
        [display]="displaySuccess"
        (event)="closeModel($event)"></app-success-model>
    <app-navigation *ngIf="loginService.isAdmin()"></app-navigation>
    <app-enterprise-navigation *ngIf="loginService.isEnterprise()"></app-enterprise-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <!-- Content Header (Page header) -->
        <section class="content-header">
            <div class="row">
                <div class="col-md-6">
                    <div class="tab-heading">
                        <span class="tab-heading-title">Manage Users</span>
                        <img
                            class="breadcrumb-separator"
                            src="assets/images/breadcrumb-separator.png" />
                        <span class="breadcrumb-custom">User Profile</span>
                        <img
                            class="breadcrumb-separator"
                            src="assets/images/breadcrumb-separator.png" />
                        <span class="breadcrumb-custom">Transactions</span>
                    </div>
                </div>
            </div>
        </section>
        <app-enterprise-links [id]="id" [isAdmin]="loginService.isAdmin()"></app-enterprise-links>
        <!-- <section class="add-section">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-md-12 section-heading">
                        Search
                    </div>
                </div>
                <div class="row pb-3">
                    <div class="col-md-6">
                        <label class="form-label">SEARCH BY SENDER OR BUSINESS</label>
                        <div class="input-icons">
                            <i class="fa fa-search icon" aria-hidden="true"></i>
                            <input class="form-input" type="text" placeholder="eg. Zakihiti’s Spaza"
                                formControlName="text">
                            <a *ngIf="form.get('text').value.length > 0" (click)="clearText()">
                                <img src="assets/images/close-x-purple.png">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label"></label>
                        <div>
                            <button class="search-button" (click)="submit()">Search</button>
                        </div>
                    </div>
                </div>
            </form>
        </section> -->
        <!-- <div class="progress-container" *ngIf="isLoading">
            <div class="progress-container-text">
                <div>Searching...</div>
            </div>
            <div>
                <div class="custom-progress-bar">
                    <span [style.width.%]="progressWidth"></span>
                </div>
            </div>
        </div> -->
        <section *ngIf="search">
            <div class="search-result-header">
                Search Results: {{ transactionList.length }} of {{ pagination.total }}
            </div>
            <hr />
            <div class="row mb-3">
                <div class="col-md-6">
                    <a
                        appExportExcel
                        [data]="excelList"
                        fileName="TransactionList"
                        class="download-transaction">
                        <u>DOWNLOAD ALL TRANSACTIONS</u>
                    </a>
                </div>
            </div>
            <div class="container-fluid tab-pane transaction-table">
                <br />
                <table id="tableData">
                    <tbody>
                        <tr
                            class="tablebox"
                            *ngFor="let tran of transactionList; let i = index"
                            [attr.data-index]="i">
                            <td>
                                <div class="transaction-row">
                                    <div class="transaction-row-element-1">
                                        <div class="transaction-type">
                                            {{ tran.type | humanize }}
                                        </div>
                                        <div
                                            class="transaction-status"
                                            *ngIf="tran.status === status.success">
                                            <img
                                                class="transaction-status-icon"
                                                src="assets/images/green-small.png" />
                                            {{ tran.status | uppercase | humanize }}
                                        </div>
                                        <div
                                            class="transaction-status"
                                            *ngIf="tran.status === status.pending">
                                            <img
                                                class="transaction-status-icon"
                                                src="assets/images/yellow-small.png" />
                                            {{ tran.status | uppercase | humanize }}
                                        </div>
                                        <div
                                            class="transaction-status"
                                            *ngIf="tran.status === status.failed">
                                            <img
                                                class="transaction-status-icon"
                                                src="assets/images/red-small.png" />
                                            {{ tran.status | uppercase | humanize }}
                                        </div>
                                        <div
                                            class="transaction-status"
                                            *ngIf="tran.status === status.onhold">
                                            <img
                                                class="transaction-status-icon"
                                                src="assets/images/blue-small.png" />
                                            {{ tran.status | uppercase | humanize }}
                                        </div>
                                        <div
                                            class="transaction-status"
                                            *ngIf="tran.status === status.processing">
                                            <img
                                                class="transaction-status-icon"
                                                src="assets/images/yellow-small.png" />
                                            {{ tran.status | uppercase | humanize }}
                                        </div>
                                        <div
                                            class="transaction-status"
                                            *ngIf="tran.status === status.cancelled">
                                            <img
                                                class="transaction-status-icon"
                                                src="assets/images/red-small.png" />
                                            {{ tran.status | uppercase | humanize }}
                                        </div>
                                    </div>
                                    <div
                                        class="transaction-row-element-2"
                                        *ngIf="!loginService.isAdmin()">
                                        <div class="transaction-user-name">
                                            {{ tran.fromUserName }}
                                        </div>
                                        <img src="assets/images/right-arrow.png" />
                                        <div class="transaction-user-name">
                                            {{ tran.toUserName }}
                                        </div>
                                    </div>
                                    <div
                                        class="transaction-row-element-2"
                                        *ngIf="loginService.isAdmin()">
                                        <div class="transaction-user-name">
                                            <a (click)="commonUtil.goToSenderProfile(tran)">
                                                <strong
                                                    ><u>{{ tran.fromUserName }}</u></strong
                                                >
                                            </a>
                                        </div>
                                        <img src="assets/images/right-arrow.png" />
                                        <div class="transaction-user-name">
                                            <a (click)="commonUtil.goToReceiverProfile(tran)">
                                                <strong
                                                    ><u>{{ tran.toUserName }}</u></strong
                                                >
                                            </a>
                                        </div>
                                    </div>
                                    <div class="transaction-row-element-3">
                                        <div
                                            class="transaction-amount"
                                            *ngIf="tran.received"
                                            style="color: #5bc79c">
                                            {{ tran.amount }}
                                        </div>
                                        <div class="transaction-amount" *ngIf="!tran.received">
                                            {{ tran.amount }}
                                        </div>
                                        <div class="transaction-date">
                                            {{ tran.date | date: 'dd/MM/yyyy, h:mm a' }}
                                        </div>
                                    </div>
                                    <div class="transaction-row-element-4">
                                        <img
                                            src="assets/images/default-transaction-detail.png"
                                            id="{{ 'logo-' + tran.transactionCode }}"
                                            (click)="
                                                showDetails(
                                                    'details-' + tran.transactionCode,
                                                    'logo-' + tran.transactionCode
                                                )
                                            " />
                                    </div>
                                </div>
                                <div
                                    class="transaction-details"
                                    id="{{ 'details-' + tran.transactionCode }}">
                                    <section class="transaction-details-section">
                                        <div class="row mb-2">
                                            <div class="col-md-8 header">Overview</div>
                                            <div
                                                class="col-md-4"
                                                *ngIf="
                                                    loginService.isEnterprise() &&
                                                    tran.type === 'payment' &&
                                                    tran.canRefund
                                                ">
                                                <a
                                                    (click)="openRefundModel(tran)"
                                                    class="float-right download-transaction"
                                                    style="color: #ea3334">
                                                    <u>REFUND TRANSACTION</u>
                                                    <img
                                                        src="assets/images/control-icon-refund.png"
                                                        class="pl-2" />
                                                </a>
                                            </div>
                                            <div
                                                class="col-md-4"
                                                *ngIf="
                                                    loginService.isEnterprise() &&
                                                    tran.type === 'payment' &&
                                                    !tran.canRefund
                                                ">
                                                <a
                                                    class="float-right download-transaction"
                                                    (click)="
                                                        showDetails(
                                                            'details-' + tran.refundTxnCode,
                                                            'logo-' + tran.refundTxnCode
                                                        )
                                                    ">
                                                    <span style="color: #ea3334">REFUNDED: </span>
                                                    <u style="color: #0a54e7">{{
                                                        tran.refundTxnCode
                                                    }}</u>
                                                </a>
                                            </div>
                                            <div
                                                class="col-md-4"
                                                *ngIf="
                                                    loginService.isEnterprise() &&
                                                    tran.type === 'refund'
                                                ">
                                                <a
                                                    class="float-right download-transaction"
                                                    (click)="
                                                        showDetails(
                                                            'details-' + tran.parentId,
                                                            'logo-' + tran.parentId
                                                        )
                                                    ">
                                                    <span style="color: #ea3334"
                                                        >PARENT TXN ID:
                                                    </span>
                                                    <u style="color: #0a54e7">{{
                                                        tran.parentId
                                                    }}</u>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label>ACCOUNT TYPE</label>
                                                        <div class="label-value">
                                                            {{ tran.toRole | humanize }}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>CASHBACKS</label>
                                                        <div class="label-value">
                                                            {{ tran.getCashbackAmount() }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label
                                                            >{{
                                                                companyService.getSelectedCompanyName()
                                                                    | uppercase
                                                            }}
                                                            TRANS ID</label
                                                        >
                                                        <div class="label-value">
                                                            {{ tran.transactionCode }}
                                                            <div>
                                                                <a
                                                                    (click)="
                                                                        copyText(
                                                                            tran.transactionCode
                                                                        )
                                                                    ">
                                                                    <img
                                                                        src="assets/images/copy-icon.png" />copy
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>EMPLOYEE NUMBER</label>
                                                        <div class="label-value">
                                                            {{ tran.toMobileNumber }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label>SENDER FEE</label>
                                                        <div class="label-value">
                                                            {{ tran.senderFee || '0.00' }}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>RECEIVER FEE</label>
                                                        <div class="label-value">
                                                            {{ tran.receiverFee || '0.00' }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label>BLOCKCHAIN TRANSACTION ID</label>
                                                        <div class="label-value">
                                                            <a
                                                                href="{{ tran.wavesTxIdUrl }}"
                                                                target="_blank">
                                                                <u>{{ tran.wavesTxIdUrl }}</u>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-12 header">
                                                        Sender Information
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label>USER NAME</label>
                                                        <div class="label-value">
                                                            {{ tran.fromUserName }}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>MOBILE NUMBER</label>
                                                        <div class="label-value">
                                                            {{ tran.fromMobileNumber }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label>BALANCE BEFORE</label>
                                                        <div class="label-value">
                                                            {{
                                                                tran.getEntTxnFromUserBalanceBefore()
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>BALANCE AFTER</label>
                                                        <div class="label-value">
                                                            {{
                                                                tran.getEntTxFromUserBalanceAfter()
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row" *ngIf="loginService.isAdmin()">
                                                    <div class="col-md-6">
                                                        <label>WALLET ID</label>
                                                        <div class="label-value">
                                                            {{ tran.fromWalletId || 'NA' }}
                                                            <div>
                                                                <a
                                                                    (click)="
                                                                        copyText(tran.fromWalletId)
                                                                    ">
                                                                    <img
                                                                        src="assets/images/copy-icon.png" />copy
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-12 header">
                                                        Receiver Information
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label>USER NAME</label>
                                                        <div class="label-value">
                                                            {{ tran.toUserName }}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>MOBILE NUMBER</label>
                                                        <div class="label-value">
                                                            {{ tran.toMobileNumber }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label>BALANCE BEFORE</label>
                                                        <div class="label-value">
                                                            {{ tran.getEntTxToUserBalanceBefore() }}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>BALANCE AFTER</label>
                                                        <div class="label-value">
                                                            {{ tran.getEntTxToUserBalanceAfter() }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row" *ngIf="loginService.isAdmin()">
                                                    <div class="col-md-6">
                                                        <label>WALLET ID</label>
                                                        <div class="label-value">
                                                            {{ tran.toWalletId || 'NA' }}
                                                            <div>
                                                                <a
                                                                    (click)="
                                                                        copyText(tran.toWalletId)
                                                                    ">
                                                                    <img
                                                                        src="assets/images/copy-icon.png" />copy
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot *ngIf="pagination.showPagination()">
                        <tr>
                            <td>
                                Rows per page:
                                <span
                                    ><b>{{ pagination.length }}</b></span
                                >
                                <app-page-length-dropdown (emitLength)="changeLength($event)">
                                </app-page-length-dropdown>
                            </td>
                            <td>
                                <app-page-navigation
                                    pageNumber="{{ pagination.getPageNo() }}"
                                    totalPages="{{ pagination.getTotalPages() }}"
                                    (changePage)="changePage($event)">
                                </app-page-navigation>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </section>
    </div>
    <div id="employeeRefundModal" class="image-modal" [style.display]="displayRefund">
        <div class="refund-employee-window">
            <section class="add-section">
                <div class="row">
                    <div class="col-md-12 section-heading">
                        Input your password to authorise a refund
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">REFUND TO</label>
                        <div class="user-details-text">
                            {{ transaction.fromUserName || '-' }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">REFUND FROM</label>
                        <div class="user-details-text">
                            {{ transaction.toUserName || '-' }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">AMOUNT</label>
                        <div class="user-details-text">
                            {{ transaction.amount || '-' }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">PASSWORD</label>
                        <div>
                            <input
                                class="form-input"
                                type="password"
                                [(ngModel)]="pin"
                                placeholder="Admin Password" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div style="margin-left: 50%">
                            <a class="deny-window-close" (click)="closeRefundModel()"
                                ><u>CANCEL</u></a
                            >
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <button
                                type="submit"
                                class="box-outline submit-btn"
                                (click)="authenticate()">
                                Authorise
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="control-sidebar-bg"></div>
</div>
