<div class="wrapper">
    <!--     <app-error-model message="{{eMessage}}" [display]="display" (event)="closeModel($event)"></app-error-model>
    <app-success-model message="{{sMessage}}" [display]="displaySuccess" (event)="closeModel($event)"></app-success-model> -->
    <app-navigation *ngIf="loginService.isAdmin()"></app-navigation>
    <app-enterprise-navigation *ngIf="loginService.isEnterprise()"></app-enterprise-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <section class="content-header">
            <div class="row">
                <div class="col-md-6">
                    <div class="tab-heading">
                        <span class="tab-heading-title">Manage Users</span>
                        <img
                            class="breadcrumb-separator"
                            src="assets/images/breadcrumb-separator.png" />
                        <span class="breadcrumb-custom">Product Orders</span>
                    </div>
                </div>
            </div>
        </section>
        <app-enterprise-links
            [id]="enterpriseId"
            [isAdmin]="loginService.isAdmin()"></app-enterprise-links>
        <app-product-orders-body [form]="form"></app-product-orders-body>
    </div>
    <div class="control-sidebar-bg"></div>
</div>
